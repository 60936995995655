<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" text v-bind="attrs" v-on="on">
          Tour umbenennen
          <v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Tour umbenennen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              label="Tourname"
              required
              v-model="tour.name"
            ></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog()">
            Schließen
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveName()">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { backButtonDialogMixin } from "../../mixins/backButtonDialogMixin";
import { createNamespacedHelpers } from "vuex";
import { UPDATE_TOUR } from "../../store/modules/userstore";
const { mapActions } = createNamespacedHelpers("user");

export default {
  name: "TourEditName",
  props: {
    tour: {
      type: Object,
    },
  },
  mixins: [backButtonDialogMixin],
  data: () => ({
    dialog: false,
  }),
  methods: {
    ...mapActions([UPDATE_TOUR]),
    saveName() {
      this.UPDATE_TOUR(this.tour);
      this.closeDialog();
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
