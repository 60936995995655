import { latLng } from "leaflet";

export default {
  calcCenterOfPois(pois) {
    const locations = pois.map((poi) => {
      return [poi.lat, poi.lon];
    });
    let sumLat = 0;
    let sumLon = 0;
    locations.forEach((element) => {
      sumLat += element[0];
      sumLon += element[1];
    });
    const location = latLng(
      sumLat / locations.length,
      sumLon / locations.length
    );
    return location;
  },
};
