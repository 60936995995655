<template>
  <div v-if="tour">
    <v-btn color="primary" text @click="share()"
      >Tour teilen<v-icon>mdi-share-variant</v-icon></v-btn
    >
    <v-overlay :value="isUploading">
      <v-progress-circular indeterminate size="64">
        Tour wird hochgeladen
      </v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="showSnackbar" :timeout="snackbarTimeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="showSnackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import { GET_API_SERVICE } from "../../store/modules/userstore";
const { mapGetters } = createNamespacedHelpers("user");

export default {
  name: "TourSharingButton",
  props: {
    tour: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters([GET_API_SERVICE]),
  },
  data() {
    return {
      isUploading: false,
      showSnackbar: false,
      snackbarText: "",
      snackbarTimeout: 2000,
    };
  },
  methods: {
    async share() {
      const shareId = await this.prepareSharing();
      if (!shareId) {
        this.snackbarText = "Fehler beim Upload der Tour";
        this.showSnackbar = true;
        return;
      }
      console.log("Got ShareId do sharing stuff");
      if (navigator.share) {
        await this.shareViaWebShare(shareId, this.tour.name);
        return;
      }
      await this.shareOldSchool(shareId);
    },
    async prepareSharing() {
      const apiService = this.GET_API_SERVICE;
      try {
        this.isUploading = true;
        const uploadTourResponse = await apiService.uploadTour(this.tour);
        this.isUploading = false;
        return uploadTourResponse.data.id;
      } catch (e) {
        console.log(e);
      }
      this.isUploading = false;
      return null;
    },
    getUrl(tourId) {
      //TODO fragile and needs better implementations
      let getUrl = window.location;
      let url = getUrl.protocol + "//" + getUrl.host; /* +
        "/" +
        getUrl.pathname.split("/")[1]*/
      if (url.includes(`tourdownload/${tourId}`)) {
        return url;
      }
      return url + `/tourdownload/${tourId}`;
    },
    async shareViaWebShare(tourId, name) {
      const url = this.getUrl(tourId);
      await navigator
        .share({
          title: `Tour: ${name}`,
          text: url,
          url: url,
        })
        .then(
          () => console.log("Successful share"),
          (error) => console.log("Error sharing:", error)
        );
    },
    async shareOldSchool(tourId) {
      var get_current_url = this.getUrl(tourId);
      await navigator.clipboard
        .writeText(get_current_url)
        .then(() => {
          this.showSnackbar = true;
          this.snackbarText = `${get_current_url} in Zwischenablage kopiert`;
        })
        .catch((err) => {
          this.snackbarText = `Error in copying text: ${err}`;
        });
    },
  },
};
</script>
