<template>
  <div>
    <v-card v-if="!getPoisLoaded">
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </v-card>
    <v-card v-if="getPoisLoaded && doesTourExist(id)">
      <v-card-title>{{ getTour(id).name }} </v-card-title>
      <v-card-text>
        <h4>Orte</h4>
        <poi-table :pois="getPois()" />
      </v-card-text>

      <v-card-text>
        <h4>Karte</h4>
        <poi-map
          style="height: 400px"
          :pois="getPois()"
          :center="getCenter()"
          :zoom="14"
        >
          <template v-slot:default="slotProps">
            <poi-title :poi="slotProps.poi" />
            <poi-detail-dialog :poi="slotProps.poi" style="margin: 0px" />
          </template>
        </poi-map>
      </v-card-text>
      <v-card-actions style="flex-wrap: wrap">
        <tour-sharing-button :tour="getTour(this.id)" />
        <tour-delete-button :tourId="this.id" :redirectOnDelete="true" />
        <tour-edit-name :tour="getTour(id)" />
        <v-btn color="primary" text @click="gotoEditPois()">
          Orte hinzufügen
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="getPoisLoaded && !doesTourExist(id)">
      <v-card-title>Tour mit {{ id }} nicht gefunden.</v-card-title>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_POI, GET_POIS_LOADED } from "../store/modules/poistore";
import { GET_TOUR } from "../store/modules/userstore";
import PoiTable from "../components/poi/PoiTable.vue";
import PoiMap from "../components/PoiMap.vue";
import TourSharingButton from "../components/tour/TourSharingButton";
import TourDeleteButton from "../components/tour/TourDeleteButton";
import centercalculator from "../services/centercalculator";
import TourEditName from "../components/tour/TourEditName.vue";
import PoiTitle from "../components/poi/PoiTitle.vue";
import PoiDetailDialog from "../components/PoiDetailDialog.vue";
import { backButtonPageMixin } from "../mixins/backButtonPageMixin";

export default {
  components: {
    PoiTable,
    PoiMap,
    TourSharingButton,
    TourDeleteButton,
    TourEditName,
    PoiTitle,
    PoiDetailDialog,
  },
  name: "Tour",
  props: ["id"],
  mixins: [backButtonPageMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getPoi: "pois/" + GET_POI,
      getPoisLoaded: "pois/" + GET_POIS_LOADED,
      getTour: "user/" + GET_TOUR,
    }),
  },
  methods: {
    doesTourExist() {
      return this.getTour(this.id) !== undefined;
    },
    getCenter() {
      return centercalculator.calcCenterOfPois(this.getPois());
    },
    getPois() {
      const poiIds = this.getTour(this.id).poiIds;
      const pois = poiIds
        .map((poiId) => {
          const p = this.getPoi(poiId);
          return p;
        })
        .filter((p) => p !== undefined);

      return pois;
    },
    gotoEditPois() {
      this.$router.push({ path: `/tour/${this.id}/editpois` });
    },
  },
};
</script>
<style></style>
