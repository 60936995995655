<template>
  <div v-if="tourId">
    <v-btn color="primary" text @click="deleteTour()"
      >Tour löschen<v-icon>mdi-delete</v-icon></v-btn
    >
    <v-snackbar v-model="showSnackbar" :timeout="snackbarTimeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="showSnackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import { REMOVE_TOUR } from "../../store/modules/userstore";
const { mapActions } = createNamespacedHelpers("user");

export default {
  name: "TourDeleteButton",
  props: {
    tourId: {
      type: String,
    },
    redirectOnDelete: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      isUploading: false,
      showSnackbar: false,
      snackbarText: "",
      snackbarTimeout: 2000,
    };
  },
  methods: {
    ...mapActions([REMOVE_TOUR]),
    deleteTour() {
      this.REMOVE_TOUR(this.tourId);
      this.snackbarText = "Tour gelöscht";
      this.showSnackbar = true;
      this.redirect();
    },
    redirect() {
      if (!this.redirectOnDelete) return;
      this.$router.push({ path: `/` });
    },
  },
};
</script>
